import { defineStore } from "pinia";

export const useSnackBarStore = defineStore("snackBar", {
  state: () => ({
    messages: [],
  }),
  actions: {
    pushMessage(text, color, lifetime = 3000) {
      this.messages.push({
        timestamp: Date.now(),
        text,
        color,
        lifetime: parseInt(lifetime),
      });
    },

    pushMessageWithUrl(text, color, url, lifetime = 3000) {
      this.messages.push({
        timestamp: Date.now(),
        text,
        color,
        lifetime: parseInt(lifetime),
        url
      });
    },

    cancelMessage(timestamp) {
      const newMessages = [];

      this.messages.forEach((message) => {
        if (timestamp !== message.timestamp) {
          newMessages.push(message);
        }
      });

      this.messages = newMessages;
    },

    cancelMessages() {
      const newMessages = [];

      this.messages = newMessages;
    },
  },
});
