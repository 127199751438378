import { defineStore } from "pinia";
import axios from "axios";
import { useRouter } from "vue-router";
import {watch} from "vue";

const router = useRouter();

export const useRouterStore = defineStore("router", {
  state: () => ({

  }),
  getters: {
    infrastructurePrefix(){
      return '/project/' +
        this.router.currentRoute.value.params.organisationConfigId + '/' +
        this.router.currentRoute.value.params.projectConfigId + '/infrastructure'
    },
    servicePrefix(){
      return '/project/' +
        this.router.currentRoute.value.params.organisationConfigId + '/' +
        this.router.currentRoute.value.params.projectConfigId + '/service/' +
        this.router.currentRoute.value.params.serviceConfigId
    },
    projectPrefix(){
      return '/project/' +
        this.router.currentRoute.value.params.organisationConfigId + '/' +
        this.router.currentRoute.value.params.projectConfigId
    },
    organisationPrefix(){
      return '/organisation/' +
        this.router.currentRoute.value.params.organisationConfigId
    },
  },

});
