import {createApp, markRaw} from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import { useMainStore } from "@/stores/main.js";
import { useStyleStore } from "@/stores/style.js";
import { useLayoutStore } from "@/stores/layout.js";
import { darkModeKey, styleKey } from "@/config.js";

import "./css/main.css";
import 'floating-vue/dist/style.css'


/* Init Pinia */
const pinia = createPinia();
pinia.use(({ store }) => { store.router = markRaw(router) });
import { VueQueryPlugin } from "@tanstack/vue-query";

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        // refetchOnWindowFocus: false,
        // refetchInterval: false,
        retry: 2,
        // refetchOnReconnect: false,
      },
    },
  },
}

/* Create Vue app */
createApp(App)
  .use(router)
  .use(pinia)
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .mount("#app");

/*
Sentry.init({
  app,
  dsn: "https://8b554760ce407db9b239c9a868a63111@o4507260598157312.ingest.de.sentry.io/4507815822491728",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.stationops\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});*/

/* Init Pinia stores */
const mainStore = useMainStore(pinia);
const styleStore = useStyleStore(pinia);
const layoutStore = useLayoutStore(pinia);

/* Responsive layout control */
layoutStore.responsiveLayoutControl();
window.onresize = () => layoutStore.responsiveLayoutControl();

/* Fetch sample data */
/* mainStore.fetch("clients");
mainStore.fetch("history");
mainStore.fetch("products");
mainStore.fetch("updates"); */

/* App style */
// styleStore.setStyle(localStorage[styleKey] ?? "basic");
styleStore.setStyle(localStorage[styleKey] ?? "white");

/* Dark mode */
if (
  (!localStorage[darkModeKey] &&
    window.matchMedia("(prefers-color-scheme: dark)").matches) ||
  localStorage[darkModeKey] === "1"
) {
  styleStore.setDarkMode(true);
}

/* Default title tag */
const defaultDocumentTitle = "StationOps";

/* Set document title from route meta */
router.afterEach((to) => {
  document.title = to.meta?.title
    ? `${to.meta.title} — ${defaultDocumentTitle}`
    : defaultDocumentTitle;
});
