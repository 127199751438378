import axios from "axios";

import {useAuthStore} from "@/stores/auth";
import router from "../router";
import {useMainStore} from "@/stores/main";


const nonInterceptedInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});



async function refreshAccessToken(originalConfig){
  originalConfig._retry = true;

  try {
    // http cookie
    const rs = await nonInterceptedInstance.post("/auth/user/refresh",{
    }, {
      withCredentials: true
    });

    if(rs.status !== 200){
      console.dir("pushing to login")
      await router.push("/login")
      return
    }

    const { accessToken, user, refreshToken, projects, organisations } = rs.data;

    useAuthStore().accessToken = accessToken;
    useAuthStore().user = user;
    useAuthStore().refreshToken = refreshToken;
    useMainStore().projects = projects;
    useMainStore().organisations = organisations;

    return axiosInstance(originalConfig);
  } catch (error) {
    if(error.response && error.response.status !== 200){
      console.dir("pushing to login")
      await router.push("/login")
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
}


axiosInstance.interceptors.request.use(
  (config) => {

    const authStore = useAuthStore();
    const token = authStore.accessToken;
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
    }else{
      return refreshAccessToken(config)
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;


    if (!originalConfig.url.startsWith("auth/user") && err.response) {
      // Access Token was expired
      console.dir("inside")

      if((err.response.status === 401 || err.response.status === 403) && originalConfig._retry){
        await router.push("/login")
        return
      }

      if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
        return refreshAccessToken(originalConfig)
      }
    }

    return Promise.reject(err.response.data);
  }
);



export default axiosInstance;


